import axios from 'axios';
import { appHelpers } from '../../../helpers';

const updateMessageStatus = (data, token) => {
  const body = {
    collection: 'messages',
    messageId: data.messageId,
    data: data.updates,
  };

  const config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/messages/updateMessageStatus`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: body,
  };

  try {
    return axios(config);
  } catch (err) {
    return { status: 'error', message: err };
  }
};

export { updateMessageStatus };
