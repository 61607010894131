import { appHelpers, properties } from '../../../helpers';
import axios from 'axios';

interface UserRequestData {
  hsId: string;
  email: string;
}

const getHubspotUser = async (
  userRequestData: UserRequestData,
  token: string
) => {
  if (!userRequestData) {
    throw new Error('Email address required');
  }

  const userProperties = properties.getObjectProperties('contacts');

  const BODY = {
    ...userRequestData,
    properties: userProperties,
  };

  const config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/users/getHubspotUser`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(config);
    const userData = data?.results[0];

    return userData;
  } catch (err) {
    return err;
  }
};

export { getHubspotUser };
