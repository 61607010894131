import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';

const useMessagesArchivedStatusUpdate = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const updateArchiveStatus = async (messageId: string) => {
    const updateData = {
      messageId: messageId,
      updates: { isArchived: true },
    };

    try {
      const token = await getAccessTokenSilently();
      await api(updateData, token).messages.updateMessageStatus();
      toast.success('Your message has been archived');
    } catch {
      toast.error('Something went wrong, please try again');
    }
  };

  const mutation = useMutation({
    mutationFn: updateArchiveStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['messages'] });
    },
  });

  const handleUpdateArchiveStatus = (messageId: string) => {
    mutation.mutate(messageId);
  };

  return { handleUpdateArchiveStatus };
};

export { useMessagesArchivedStatusUpdate };
