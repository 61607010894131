//Used for first part of API URL
import { appHelpers } from '../../../helpers';
//Used to get Properties for GET Requests
import { CONTRACT_PROPERTIES } from '../../../constants/contractProperties';
//Used to get Object ID of Contracts
import { ids } from '../../../helpers';

import axios from 'axios';

import { ApiRequestData } from '../../../types/api';
import { AssociationRequestData } from '../../../types/associations';

interface ContractsRequestData extends ApiRequestData {
  userCompanyId: string;
}

const getContracts = async (
  requestData: ContractsRequestData,
  token: string
) => {
  if (!requestData) {
    throw new Error('Unable to fetch invoices');
  }

  const { hsId } = requestData;

  const associationRequestData: AssociationRequestData = {
    objectFromType: null,
    recordId: null,
  };

  const objectIds = ids.getObjectIds();

  associationRequestData.objectFromType = objectIds ? objectIds.contacts : null;
  associationRequestData.recordId = hsId;

  const BODY = {
    objectType: objectIds ? objectIds.contracts : null,
    properties: CONTRACT_PROPERTIES,
    associationData: associationRequestData,
  };

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/contracts/getContracts`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching contracts: ${err.message}`);
    } else {
      throw new Error(`Error fetching contracts`);
    }
  }
};

export { getContracts };
