import { useAuth0 } from '@auth0/auth0-react';
import { api } from '../../api/apiHandler';
import { ids } from '../../helpers';

const useResolveTicket = () => {
  const { getAccessTokenSilently } = useAuth0();

  const resolveTicket = async (ticketId: string, status: string) => {
    const pipelineStageIds = ids.getPipelineStageIds();

    const pipelineId = pipelineStageIds
      ? pipelineStageIds[status as keyof typeof pipelineStageIds]
      : null;

    const TICKET_DATA = {
      id: ticketId,
      body: {
        properties: {
          hs_pipeline_stage: pipelineId,
        },
      },
    };

    try {
      const token = await getAccessTokenSilently();
      return await api(TICKET_DATA, token).tickets.updateTicket();
    } catch (err) {
      throw new Error(`Error updating ticket #${ticketId}: ${err}`);
    }
  };

  return { resolveTicket };
};

export { useResolveTicket };
