import { useAuth0 } from '@auth0/auth0-react';
import { api } from '../../api/apiHandler';
import { ids, timeDate } from '../../helpers';

type EmailData = {
  hsId: string;
  email: string;
  firstName: string | undefined;
  lastName: string | undefined;
  message: string;
  ticketId: string | undefined;
};

export function useReplyToTicket() {
  const { getAccessTokenSilently } = useAuth0();

  async function replyToTicket(data: EmailData) {
    const timeStamp = timeDate.createTimeStamp();
    const associationIds = ids.getAssociationIds();

    const emailHeaders = {
      from: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      },
      sender: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      },
      to: [
        {
          email:
            'npower Business Solutions Energy Generators Team<energygenerators@npowerbusinesssolutions.com>',
        },
      ],
    };

    const associations = [
      {
        to: { id: data.hsId },
        types: [
          {
            associationCategory: 'HUBSPOT_DEFINED',
            associationTypeId: associationIds
              ? associationIds.email_to_contact
              : null,
          },
        ],
      },
      {
        to: { id: data.ticketId },
        types: [
          {
            associationCategory: 'HUBSPOT_DEFINED',
            associationTypeId: associationIds
              ? associationIds.email_to_ticket
              : null,
          },
        ],
      },
    ];

    const emailData = {
      subject: `Update to ticket #${data.ticketId}`,
      message: data.message,
      headers: emailHeaders,
      timeStamp,
      associations,
    };

    try {
      const token = await getAccessTokenSilently();

      const response = await api(emailData, token).email.createEmail();
      return response;
    } catch (error) {
      console.error('Error getting access token or sending email:', error);
      throw new Error('Error getting access token or sending email');
    }
  }

  return { replyToTicket };
}
